<template>
    <div class="order-page">
        <div class="container-fluid">
            <div v-if="order">
                <h1 v-if="order.place_order_time">Zamówienie {{ orderId }}</h1>
                <h1 v-if="!order.place_order_time">Koszyk {{ orderId }}</h1>
            </div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <div v-else >
                <div class="row" v-if="order.place_order_time">
                    <div class="col-12">
                        <b-button-group>
                            <b-button v-if="order.status!=2" variant="secondary" v-on:click="setOrderStatus(order.id,2)" class="m-1">
                                <strong>Ustaw opłacone</strong>
                            </b-button>
                            <b-button v-if="order.status!=4" variant="secondary" v-on:click="setOrderStatus(order.id,4)" class="m-1">
                                <strong>Anuluj</strong>
                            </b-button>
                            <b-button v-if="order.status!=3" variant="secondary" v-on:click="setOrderStatus(order.id,3)" class="m-1">
                                <strong>Zakończ</strong>
                            </b-button>
                            <b-button variant="danger" :to="{name: 'AdminOrderDelete', params: {orderId: orderId} }" class="m-1">
                                <strong>Usuń</strong>
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <h3>Zamawiający</h3>
                        <div v-if="order.user">
                             <router-link :to="{name: 'AdminUserPreview',  params: { userId: order.user_id } }" >
                                <strong>{{ order.user.fullname }}</strong>
                            </router-link><br/>
                            {{ order.user.email }}<br/>
                            {{ order.user.phone }}<br/>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <h3>Dostawa</h3>
                        <div v-if="order.address">
                            {{ order.address.street }} {{ order.address.number }}<br/>
                            {{ order.address.postcode }} {{ order.address.city }}<br/>
                            {{ order.address.comment }}<br/>
                            trasa: {{ order.address.route_id }}<br/>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <h3>Status: {{order.statusname}}</h3>
                        <div>Typ: 
                            <span v-if="order.type==1" class="">Abonament</span>
                            <span v-if="order.type==2" class="">Jednorazowe</span>
                        </div>
                        <div v-if="order.start_date == order.end_date">{{ order.end_date }}</div>
                        <div v-else>od {{order.start_date}} do {{ order.end_date }}</div>
                        <br />
                        Status płatności:
                        <span v-if="order.paid==0" class="text-danger">Nie opłacone</span>
                        <span v-if="order.paid==1" class="text-success">Rozliczone</span>
                        <div>Suma: {{order.sum_price}} zł </div>
                        <div>Opakowanie: {{order.box_price}} zł </div>
                        <div>Dostawa: {{order.delivery_price}} zł </div>
                        <div>Kupon: {{order.code}}</div>
                    </div>
                </div>
                    <br/>
                    <br/>
                    <br/>
                <div class="row">
                    <div class="col-12 col-xs-12">
                        <h3>Produkty</h3>
                        <b-table :items="order.products" :fields="fields_products" :show-empty="true"
                                class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                            >
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xs-12">
                        <h3>Dostawy</h3>
                        <b-table :items="order.deliveries" :fields="fields_deliveries" :show-empty="true"
                                class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                            >
                            <template #cell(options)="data">
                                <router-link :to="{name: 'AdminDeliveryReport',  params: { deliveryId: data.item.id } }" >
                                    <button class="btn btn-secondary" >Zobacz</button>
                                </router-link>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-xs-12">
                        <h3>Płatności</h3>
                        <b-table :items="order.payments" :fields="fields_payments" :show-empty="true"
                                class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                            >
                        </b-table>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'AdminOrder',
    data() {
        return {
            isLoading: true,
            order: null,
            orderId: this.$route.params.orderId,
            fields_products: [
                { label: 'Product', key: 'product.name', sortable: true },
                { label: 'Poniedziałek', key: 'monday_amount', sortable: true },
                { label: 'Wtorek', key: 'tuesday_amount', sortable: true },
                { label: 'Środa', key: 'wednesday_amount', sortable: true },
                { label: 'Czwartek', key: 'thursday_amount', sortable: true },
                { label: 'Piątek', key: 'friday_amount', sortable: true },
                { label: 'Sobota', key: 'saturday_amount', sortable: true },
                { label: 'Jednorazowa', key: 'date_amount', sortable: true }
            ],
            fields_deliveries: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Data', key: 'date', sortable: true },
                { label: 'Kierowca', key: 'driver.fullname', sortable: true },
                { label: 'Komentarz', key: 'comments', sortable: true },
                { label: 'Status', key: 'statusname', sortable: false },
                { label: '', key: 'options', sortable: true }
            ],
            fields_payments: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Provider', key: 'payment_provider', sortable: true },
                { label: 'Status info', key: 'status_info', sortable: false },
                { label: 'Status', key: 'paid', sortable: true },
                { label: 'Próby', key: 'payment_try', sortable: true },
                { label: 'Suma', key: 'sum_price', sortable: true },
                { label: 'Data', key: 'created_at', sortable: true }
            ]
        }
    },
    computed: {
    },
    mounted() {
        this.getOrder()
    },
    methods: {
        getOrder() {    
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/order/' + this.orderId ).then( (responce) => {
                this.isLoading =  false;
                this.order = responce.data.order;
                if(this.order.type == 2) {
                    this.fields_products = [
                         { label: 'Product', key: 'product.name', sortable: true },
                         { label: 'Jednorazowa', key: 'date_amount', sortable: true }
                    ];
                } else {                    
                    this.fields_products = [
                        { label: 'Product', key: 'product.name', sortable: true },
                        { label: 'Poniedziałek', key: 'monday_amount', sortable: true },
                        { label: 'Wtorek', key: 'tuesday_amount', sortable: true },
                        { label: 'Środa', key: 'wednesday_amount', sortable: true },
                        { label: 'Czwartek', key: 'thursday_amount', sortable: true },
                        { label: 'Piątek', key: 'friday_amount', sortable: true },
                        { label: 'Sobota', key: 'saturday_amount', sortable: true }
                    ];
                }
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd pobierania danych`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            })
        },
        setOrderStatus(orderID, statusID) {
            if(confirm("Na pewno?")) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
                axios.post('/admin/ordersetstatus', {id: orderID, status: statusID} ).then( () => {
                    this.isLoading =  false;
                    this.getOrder();
                }).catch( () => {
                    this.isLoading = false;
                    this.$bvToast.toast(`Błąd pobierania danych`, {
                        title: 'Błąd',
                        autoHideDelay: 5000,
                        solid: true,
                        variant: 'danger',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                })
            } else {
                    this.isLoading =  false;
            }
        }
    },
}
</script>
